import React from 'react';

function Skills() {
  return (
    <section className="bg-gradient-animation py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-3xl font-bold text-white mb-8">Skills</h2>

        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-lg">
          <h3 className="text-2xl font-bold mb-4 text-white">Technical Skills</h3>
          <ul className="list-disc list-inside text-base text-gray-100">
            <li className="mb-1">Advanced knowledge of C++, MATLAB, JavaScript, Node.js, React, and Android Studio</li>
            <li className="mb-1">Experience with laboratory techniques</li>
            <li className="mb-1">Working knowledge of ISO 13485:2016</li>
          </ul>
        </div>

        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-lg">
          <h3 className="text-2xl font-bold mb-4 text-white">Certifications</h3>
          <ul className="list-disc list-inside text-base text-gray-100">
          <li className="mb-1">Fundamental Neuroscience for Neuroimaging - Johns Hopkin's University (online) </li>
            <li className="mb-1">CPR certified</li>
            <li className="mb-1">Valid driver's license</li>
          </ul>
        </div>

        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-lg">
          <h3 className="text-2xl font-bold mb-4 text-white">Languages</h3>
          <ul className="list-disc list-inside text-base text-gray-100">
            <li className="mb-1">Fluent in English</li>
            <li className="mb-1">Advanced knowledge of Hebrew and Zulu</li>
          </ul>
        </div>

        
        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-lg">
          <h3 className="text-2xl font-bold mb-4 text-white">Personal Traits</h3>
          <ul className="list-disc list-inside text-base text-gray-100">
            <li className="mb-1">Hardworking</li>
            <li className="mb-1">collaborative</li>
            <li className="mb-1">enthusiastic</li>
            <li className="mb-1">copes well under pressure</li>
          </ul>
        </div>

          </div>
    </section>
  );
}

export default Skills;
