import React from 'react';
import { FiUser } from 'react-icons/fi';

function AboutMe() {
  return (
    <section id="about-me" className="bg-gradient-animation p-8 text-white">
      <div className="max-w-5xl mx-auto">
        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-l">
          <h3 className="text-3xl font-extrabold mb-4">About Me</h3>
          <ul className="list-disc list-inside text-lg">
            <li className="mb-2">
              <FiUser className="inline mr-2 text-xl" />
              I hold a degree in Biomedical Engineering and am currently pursuing my third year in Electrical and Information Engineering. My focus centers around my passion for leveraging data analytics, machine learning, and AI to enhance medical diagnostics.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
