import React from "react";

function Personal() {
  return (
    <section id="Personal" className="rounded-lg bg-gradient-animation p-8">
      <div className="max-w-5xl mx-auto">
        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-lg">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-white font-bold text-center mb-4">
            MAX BRENNER
          </h1>
        </div>
      </div>
    </section>
  );
}

export default Personal;
