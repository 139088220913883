import React from 'react';
import { FaGraduationCap } from 'react-icons/fa';

function Education() {
  return (
    <section className="bg-gradient-animation py-16 px-4 sm:px-6 lg:px-8 text-white">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-4xl font-bold mb-8">Education</h2>

        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-l">
          <h3 className="text-3xl font-extrabold mb-4">Bachelor of Engineering Science in Biomedical Engineering</h3>
          <p className="text-lg text-gray-200 mb-2">University of the Witwatersrand</p>
          <ul className="list-disc list-inside text-lg text-gray-200">
            <li className="mb-2">2020-2022</li>
            <li className="mb-2">Outcome: Graduated Cum Laude with Distinction</li>
          </ul>
        </div>

        <div className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-l">
          <h3 className="text-3xl font-extrabold mb-4">Bachelor of Engineering in Electrical - Information - Engineering</h3>
          <p className="text-lg text-gray-200 mb-2">University of the Witwatersrand</p>
          <ul className="list-disc list-inside text-lg text-grnpmay-200">
            <li className="mb-2">2023-2024</li>
            <li className="mb-2">Outcome: pending completion</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Education;
