import React from 'react';
import { useSpring, animated } from 'react-spring';
import { FaCode, FaChalkboardTeacher, FaFlask } from 'react-icons/fa';

function WorkExperience() {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 100 },
  });

  return (
    <section className="bg-gradient-animation py-16 px-4 sm:px-6 lg:px-8 text-white">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-4xl font-bold mb-8">Work Experience</h2>

        <animated.div style={fadeIn} className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-lg">
          <h3 className="text-3xl font-extrabold mb-4">Software Developer Intern</h3>
          <p className="text-lg text-gray-200 mb-2">Syft Analytics, 2022-2023</p>
          <ul className="list-disc list-inside text-lg text-gray-200">
            <li className="mb-2">Developed Campaign Management website</li>
            <li className="mb-2">Implemented API functionality for lead generation tools</li>
            <li className="mb-2">Optimized and enhanced website performance</li>
          </ul>
        </animated.div>

        <animated.div style={fadeIn} className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-l">
          <h3 className="text-3xl font-extrabold mb-4">Private Tutor</h3>
          <p className="text-lg text-gray-200 mb-2">Self-Employed, 2019-2023</p>
          <ul className="list-disc list-inside text-lg text-gray-200">
            <li className="mb-2">Tutored students from years 7-11 in Mathematics, Life Sciences, Physical Sciences, and Zulu</li>
            <li className="mb-2">Experience with both IEB and NSC syllabuses</li>
          </ul>
        </animated.div>

        <animated.div style={fadeIn} className="mb-16 bg-gradient-to-r from-green-400 via-blue-500 to-blue-900 p-6 rounded-l">
          <h3 className="text-3xl font-extrabold mb-4">Pharmaceutical Research Intern</h3>
          <p className="text-lg text-gray-200 mb-2">Hebrew University of Jerusalem, 2019</p>
          <ul className="list-disc list-inside text-lg text-gray-200">
            <li className="mb-2">Developed Lipiodol-PCL complex nanoparticles for the delivery of various chemotherapy agents</li>
            <li className="mb-2">Improved process for generating Janus Colloidal Particles</li>
            <li className="mb-2">Assisted researchers with their projects</li>
          </ul>
        </animated.div>
      </div>
    </section>
  );
}

export default WorkExperience;
