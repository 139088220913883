import logo from './logo.svg';
import './App.css';
import MyCV from './components/MyCv';
import React from 'react';
import Navbar from './components/NavBar';
import AboutMe from './components/AboutMe';
import Education from './components/Education';
import WorkExperience from './components/WorkExperience';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Personal from './components/Personal';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6-3hA0JUkxbADF5PdvquSW98_g6ipvDQ",
  authDomain: "my-website-28274.firebaseapp.com",
  projectId: "my-website-28274",
  storageBucket: "my-website-28274.appspot.com",
  messagingSenderId: "99812166623",
  appId: "1:99812166623:web:ea16fd10e6ee744e07526a",
  measurementId: "G-KSP45W7VN4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="min-h-screen">
      {/* <Navbar /> */}
      <Personal/>
      <AboutMe />
      <Education />
      <WorkExperience />
      <Skills />
      <Contact />
    </div>
  );
}



export default App;
